.messages {
  flex-direction: column;
  align-items: center;
  justify-content: space-between !important;
  height: 100%;
  animation-timing-function: ease-in;
  text-transform: capitalize;
  /*font-size: 12px;*/
  animation: myfade 0.5s;
}

@keyframes scrollup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
