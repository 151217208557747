.page-intro {
  /*background-color: aqua;*/
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  /*justify-content: space-between;*/
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 30px;
  /*opacity: 0;*/
  animation: 'myfade 1s';
  animationtimingfunction: 'ease-in';
}

@keyframes myfade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.balls {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 60px;
}

.balls div {
  margin-top: 20px;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(-100%);
  animation: wave 0.8s ease-in-out alternate infinite;
}

.balls div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.balls div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes wave {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}
