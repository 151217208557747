.air {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  background-position: center center;
  background-attachment: fixed;

  background-size: cover;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.air::before {
  content: "";
  position: absolute;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  z-index: -1;
  background: url("../images/imgonline-com-ua-TextureSeamless-aDKMx5Ydxoq.png")
    0 0 repeat;
  animation: rotation 500s infinite linear;
  animation-direction: normal;
}

@media (min-width: 481px) and (max-width: 1024px) {
  .air::before {
    background-image: url("../images/night-sky_1440_seamless.png");
  }
}

@media (min-width: 1025px) {
  .air::before {
    background-image: url("../images/night-sky_2560_seamless.png");
  }
}
