.embers {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 40%;
  overflow: visible;
}

.embers svg {
  filter: none;
}

sparkle-div.cover {
  overflow: visible;
  font-size: 3px;
  color: rgb(244, 238, 181);
  filter: drop-shadow(0px 0px 5px rgb(244, 238, 181));
}
