.fire {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.fire .fire-holder {
  pointer-events: none;
}

.fire .fire-holder .container {
  height: 100%;
}

.fire .fire-holder .container svg {
  filter: blur(20px);
  z-index: -1;
}
.fire .css-qq4ait {
  z-index: 11;
}
.fire .page-intro {
  z-index: 11;
}

/*0 0 500 425*/
/*200 0 100 425*/

.fire-bg {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: -3;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 480px) {
  .fire-bg {
    background-image: url("../images/BurningClose_414.png");
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .fire-bg {
    background-image: url("../images/BurningClose_1440.png");
  }
}

@media (min-width: 1025px) {
  .fire-bg {
    background-image: url("../images/BurningClose_2560.png");
  }
}
