.container {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-end;
    justify-content: center;
    
}

svg {
  z-index: 10;
  overflow: visible;
  filter: blur(8px);
}

.stick {
  transform: translatex(100px) translatey(360px);
  filter: brightness(200%);
}

.flame {
  mix-blend-mode: lighten;
  pointer-events: none;
}
