.fire-holder {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.App {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.switch-wrapper {
  height: 100%;
}

.css-1oprsjx {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.main {
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

@media (max-width: 480px) {
  .main {
    background-image: url('../images/HearthHome_414.png');
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .main {
    background-image: url('../images/HearthHome_1440.png');
  }
}

@media (min-width: 1025px) {
  .main {
    background-image: url('../images/HearthHome_2560.png');
  }
}
