.messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  animation-timing-function: ease-in;
  animation: msgfade 1.5s;
  font-size: 36;
}

@keyframes msgfade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.air .scroll-button {
  height: 100%;
}

.scroll-holder {
  margin: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
