.login-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(10, 5, 37, 1) 9%,
    rgba(50, 73, 108, 1) 42%,
    rgba(98, 111, 131, 1) 72%,
    rgba(203, 153, 128, 1) 100%
  );
}

.login-wrapper-heading {
  font-size: 60px !important;
  width: 280px;
  line-height: 50px !important;
  text-align: center !important;
  /*flex-direction: */
}

.login-wrapper button {
  /*font-family: 'CanelaLight';*/
  /*font-size: 50px;*/
  /*width: 27%;*/
  letter-spacing: 2px;
  margin-top: 40px;
  text-transform: uppercase;
  background: transparent;
  /*text-transform: capitalize;*/
  border: none;
  border-bottom: 1px solid #fff;

  color: white;
  padding: 1em;
}

.full {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
