@font-face {
  font-family: 'ApercuPro';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url(./fonts/apercu-regular-pro.woff) format('woff'), url(./fonts/ApercuPro.otf) format('opentype');
}

@font-face {
  font-family: 'CanelaLight';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(./fonts/Canela-Light-Web.woff) format('woff'), url(./fonts/Canela-Light.otf) format('opentype');
}
